.dropdown {
  position: relative;
  display: inline-block;
  text-align: center;
}

.dropdown > button {
  cursor: pointer;
  position: relative;
  display: inline-block;
  border: 1px solid #000;
  padding: 10px 17px;
  overflow: hidden;
  background: none;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  z-index: 2;
  width: 100%;
  background-color: transparent;
  margin-top: 15px;
  cursor: pointer;
  vertical-align: middle;
  position: relative;
  z-index: 1;
  background-color: transparent;
  color: rgb(33, 33, 33);
}

.button:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  z-index: -1;
  opacity: 0;
  transform: scale3d(0.7, 1, 1);
  transition: transform 0.2s, opacity 0.3s;
  transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
}

.button:hover:before {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.dropdown > button[aria-expanded='true'] {
  background-color: #000;
  color: #fff;
}

.dropdown > button[aria-expanded='true']:before {
  transform: translateY(calc(100% - 3px));
}

.dropdown > button[aria-expanded='true']:after {
  border-width: 0 5px 5px 5px;
  border-color: transparent transparent #fff transparent;
}

.dropdown > button:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  z-index: -1;
  transition: all 0.3s cubic-bezier(1, 0.1, 0, 0.9);
  transform: translateZ(0) translateY(0);
}

.dropdown > button:after {
  content: '';
  position: absolute;
  top: 50%;
  right: 30px;
  display: block;
  margin-top: -3px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 5px 0 5px;
  border-color: #000 transparent transparent transparent;
  opacity: 1;
  transition: all 0.3s ease;
}

.dropdown > button:focus:before {
  background: transparent;
}

.dropdown button + ul {
  position: absolute;
  overflow: hidden;
  top: 100%;
  left: 0;
  right: 0;
  margin: 0;
  padding: 0;
  background: rgb(251, 250, 248);
  list-style: none;
  transition: all 0.3s cubic-bezier(1, 0.1, 0, 0.9) 0.05s;
  transform: translateZ(0);
  max-height: 200px;
  visibility: visible;
  z-index: 1;
  border: 1px solid black;
}

.dropdown button + ul[aria-hidden='true'] {
  max-height: 0;
  visibility: hidden;
  transform: translateZ(0);
}

.dropdown button + ul li a {
  display: block;
  color: rgb(33, 33, 33);
  padding: 10px 30px;
  text-decoration: none;
  font-size: 14px;
  transition: all 0.3s ease;
  font-family: 'brandon-grotesque', sans-serif;
  font-style: normal;
  font-weight: 600;
  text-transform: uppercase;
}

.dropdown button + ul li a:hover,
.dropdown button + ul li a:focus {
  background: #f1f1f1;
}
