.header {
  padding-top: 25px;
  padding-bottom: 15px;
}

@media (min-width: 768px) {
  .header {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

.logo {
  letter-spacing: 4px;
  font-size: 2em;
  margin-top: -4px;
  text-align: center;
  line-height: 1.1;
}

@media (min-width: 768px) {
  .logo {
    text-align: left;
  }
}

.mobileNavToggle {
  font-family: 'brandon-grotesque', sans-serif;
  font-style: normal;
  font-weight: 600;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  display: block;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 10px;
}
