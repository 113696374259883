.app {
    background-color: #fbfaf8;
    color: #212121;
    font-family: 'jubilat', serif;
    font-style: normal;
    font-weight: 400;
    min-height: 100vh;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
}

.main {
    -ms-flex: 1;
    flex: 1;
    display: flex;
    flex-direction: column;
}

.main div a,
.main div a:hover {
  text-decoration: underline;
}