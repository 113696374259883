.footer {
  font-size: 13px;
  margin-top: 80px;
  padding-top: 20px;
  padding-bottom: 20px;
  border-top: 1px solid #000;
  width: 100%;
}

.footerContent {
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.footerContent > * {
  width: 100%;
  text-align: center;
}

@media (min-width: 992px) {
  .footer {
    padding-top: 20px;
    padding-bottom: 40px;
    margin-top: 120px;
  }

  .footerContent {
    -ms-flex-direction: row;
    flex-direction: row;
    justify-content: space-between;
  }

  .footerContent > * {
    width: auto;
    text-align: left;
  }

  .footerContent > * + * {
    margin-top: 20px;
  }

  .footer .legal {
    padding-top: 15px;
  }
}
